<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" :loading="serviceRequestProcessing">
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
          <p class="mt-4 text-center">Processing...</p>
        </template>
        <v-card-title
          v-if="!serviceRequestProcessing"
          class="orange-bg font-weight-bold white--text"
        >
          Update Service Request
          <v-btn small class="orange-bg white--text ml-4" @click="printRequest">
            Print
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3" v-if="!serviceRequestProcessing">
          <v-container>
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Request #: </span>
              </v-col>
              <v-col cols="12" md="11">
                {{ serviceRequest.id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Request Type: </span>
              </v-col>
              <v-col cols="12" md="11">{{
                serviceRequest.requestType.name
              }}</v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Title: </span>
              </v-col>
              <v-col cols="12" md="11"
                >{{ serviceRequest.title }} {{ serviceRequest.id }}</v-col
              >
            </v-row> -->
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Due Date: </span>
              </v-col>
              <v-col cols="12" md="11">
                {{ formatDate(serviceRequest.targetCompletionDate) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Community: </span>
              </v-col>
              <v-col cols="12" md="11">{{
                serviceRequest.communityName
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Opened By: </span>
              </v-col>
              <v-col cols="12" md="11"
                >{{ getCreatedBy(serviceRequest.createdBy) }}
                {{ formatDate(serviceRequest.created) }}</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Assigned To: </span>
              </v-col>
              <v-col cols="12" md="11">{{
                getAssignedTo(serviceRequest.assignedTo)
              }}</v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Priority: </span>
              </v-col>
              <v-col cols="12" md="11"
                >{{ serviceRequest.priority.name }}
                <v-icon
                  small
                  :color="
                    serviceRequest.priority.name === 'Normal'
                      ? 'green'
                      : serviceRequest.priority.name === 'High'
                      ? 'red'
                      : 'blue'
                  "
                >
                  fas fa-circle
                </v-icon></v-col
              >
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <div>
                  <span class="font-weight-bold">Location: </span>
                </div>
                {{ serviceRequest.location }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div>
                  <span class="font-weight-bold">Description: </span>
                </div>
                {{ serviceRequest.description }}
              </v-col>
            </v-row>
            <v-row v-if="serviceRequest.isNeighborhoodLoginRequest">
              <v-col cols="12" md="1">
                <span class="font-weight-bold">HOA or Individual: </span>
              </v-col>
              <v-col cols="12" md="11">{{
                serviceRequest.isHoaRequest
                  ? "Association Request"
                  : "Individual Home - Extra Service"
              }}</v-col>
            </v-row>
            <v-row v-if="serviceRequest.isNeighborhoodLoginRequest">
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Neighborhood Name: </span>
              </v-col>
              <v-col cols="12" md="11">{{
                serviceRequest.neighborhoodName
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Contact Name: </span>
              </v-col>
              <v-col cols="12" md="11">{{ serviceRequest.contactName }}</v-col>
            </v-row>
            <v-row v-if="serviceRequest.isNeighborhoodLoginRequest">
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Home Address: </span>
              </v-col>
              <v-col cols="12" md="11">{{ serviceRequest.homeAddress }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Contact Phone: </span>
              </v-col>
              <v-col cols="12" md="11">{{ serviceRequest.contactPhone }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Contact Email: </span>
              </v-col>
              <v-col cols="12" md="11">{{ serviceRequest.contactEmail }}</v-col>
            </v-row>
            <v-row v-if="serviceRequest.isNeighborhoodLoginRequest">
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Preferred Contact Method: </span>
              </v-col>
              <v-col cols="12" md="11">{{
                serviceRequest.isEmailPreferred ? "Email" : "Phone"
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="1">
                <span class="font-weight-bold">Attachments </span>
              </v-col>
              <v-col>
                <div
                  v-for="(item, i) in serviceRequest.attachments.$values"
                  :key="'c' + i"
                >
                  <a :href="item.fileUrl">{{ item.fileName }}</a>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-for="(item, i) in serviceRequest.comments.$values.filter(
              el => !el.isInternal
            )"
            :key="'c' + i"
          >
            <hr style="background-color: #df6526; height: 1px;" />
            <v-row>
              <v-col class="green--text"
                >{{ getCreatedBy(item.createdBy) }}
                {{ formatDate(item.created) }}</v-col
              >
            </v-row>
            <v-row>
              <v-col>{{ item.description }}</v-col>
            </v-row>
            <v-row>
              <v-col>
                <div
                  v-for="(commentAttachmentItem, i) in item.attachments.$values"
                  :key="'c' + i"
                >
                  <a target="_blank" :href="commentAttachmentItem.fileUrl">
                    {{ commentAttachmentItem.fileName }}
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <hr style="background-color: #df6526; height: 1px;" />
          </v-container>
          <!-- Start New Service Request section -->
          <v-card
            id="updateRequestForm"
            elevation="0"
            outlined
            color="#f1f1f1"
            v-if="
              permissions &&
                permissions.serviceRequest &&
                permissions.serviceRequest.canEdit
            "
          >
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-radio-group
                        v-model="requestStatus"
                        row
                        dense
                        :disabled="serviceRequest.isCompleted"
                      >
                        <v-radio
                          label="Open"
                          color="primary"
                          value="open"
                          class="v-radio-no-margin"
                          v-show="!serviceRequest.isCompleted"
                          :disabled="
                            serviceRequest.isInProcess ||
                              serviceRequest.isCompleted
                          "
                        >
                        </v-radio>
                        <v-radio
                          label="In-Process"
                          color="primary"
                          value="inProcess"
                          class="v-radio-no-margin"
                          v-show="!serviceRequest.isCompleted"
                          :disabled="isResident || serviceRequest.isCompleted"
                        >
                        </v-radio>
                        <v-radio
                          label="Complete"
                          color="primary"
                          value="complete"
                          class="v-radio-no-margin"
                        >
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea
                        v-model="newRequest.description"
                        label="* Update Service Request"
                        required
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        :loading="loadingServiceRequestTypes"
                        v-model="requestType"
                        :items="requestTypeItems"
                        item-text="name"
                        item-value="id"
                        label="* Choose type..."
                        :rules="[v => !!v || 'Type is required']"
                        required
                      >
                      </v-select>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-radio-group
                        v-model="requestType"
                        label="* Choose Type"
                      >
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            lg="4"
                            v-for="(requestTypeItem, i) in requestTypeItems"
                            :key="'request_type_col_' + i"
                          >
                            <v-radio
                              :value="requestTypeItem.id"
                              :label="requestTypeItem.name"
                            ></v-radio>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-row>
                      <v-col cols="12" md="4">
                        <div
                          v-for="attachment in attachmentsObj"
                          cols="12"
                          class="darggable-col"
                          :key="attachment.id"
                        >
                          <MediaUploader
                            class="pb-5"
                            uploadType="file"
                            :key="attachment.id"
                            :id="`multiFilePost_${attachment.id}`"
                            :index="attachment.id"
                            :accept="accept"
                            :multiple="true"
                            :extensions="extensions"
                            :sizeImage="10485760"
                            :sizeVideo="104857600"
                            :fileId.sync="attachment.fileId"
                            :file-url.sync="attachment.url"
                            :isUploading.sync="isFileUploading"
                            @onMultipleUploaded="onMultipleAddFile"
                            @onChange="onChange"
                            @onDeleteFile="onDeleteFile"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-row>
                  <!-- <v-row>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="priority"
                        :items="priorityItems"
                        item-text="name"
                        item-value="id"
                        label="* Choose priority..."
                        required
                      ></v-select>
                    </v-col>
                  </v-row> -->
                  <!-- Please indicate the residence address, address in close proximity or the location in the community (i.e. entrance, pool area, end of Whitehosrse dr., etc.) relating to this service request:-->
                  <!-- <v-row v-if="!serviceRequest.isNeighborhoodRequest">
                    <v-col cols="12" md="6">
                      <v-textarea
                        v-model="newRequest.location"
                        label="Location:"
                      ></v-textarea>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="assignedTo"
                        :items="ynTeamItems"
                        item-text="fullName"
                        item-value="id"
                        label="* Assigned to"
                        required
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <p style="font-size: larger; font-weight: bold;">
                        * Indicates required fields
                      </p>
                    </v-col>
                  </v-row>
                  <v-btn
                    color="orange-bg white--text"
                    @click="saveServiceRequest"
                  >
                    Update Service Request
                  </v-btn>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>

          <!-- End New Service Request section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  POST_DATA,
  API_CUSTOMERS,
  // API_EMPLOYEES,
  API_SERVICE_REQUESTS,
  API_SERVICE_REQUEST_TYPES
  // API_SERVICE_REQUEST_PRIORITIES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";
import permissionsHelper from "@/core/helpers/permissionsHelper";
import Mgr from "@/core/services/security.service";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    MediaUploader
  },
  data: () => ({
    mgr: new Mgr(),
    userInfo: null,
    communityInfo: {},
    serviceRequest: {},
    serviceRequestProcessing: false,
    requestStatus: "open",
    newRequest: {
      communityName: "",
      title: "",
      requestType: "",
      description: "",
      isInternal: false,
      attachments: [],
      // priority: "",
      assignedTo: "",
      targetCompletionDate: null,
      location: ""
    },
    menu1: false,
    completionDate: null,
    formattedCompletionDate: null,
    requestType: "",
    requestTypeItems: [
      // {
      //   name: "Maintanence",
      //   value: "Maintanence"
      // },
      // {
      //   name: "Landscape Installation",
      //   value: "Landscape Installation"
      // },
      // { name: "Irrigation", value: "Irrigation" },
      // { name: "Plant/Turf Health", value: "Plant/Turf Health" },
      // { name: "Storm/Other Damage", value: "Storm/Other Damage" },
      // { name: "Resident Request", value: "Resident Request" },
      // { name: "Customer Complaint", value: "Customer Complaint" },
      // { name: "QA", value: "QA" },
      // { name: "New Opportunity", value: "New Opportunity" },
      // { name: "Other", value: "Other" },

      { name: "General Service", value: "General Service" },
      { name: "Irrigation", value: "Irrigation" },
      { name: "Storm/Other Damage", value: "Storm/Other Damage" },
      { name: "Request for proposal", value: "Request for proposal" }
    ],

    ynTeamItems: [],

    // priority: "",
    // priorityItems: [
    //   { name: "Low", value: "Low" },
    //   { name: "Normal", value: "Normal" },
    //   { name: "High", value: "High" }
    // ],
    assignedTo: null,
    options: {
      page: 1,
      itemsPerPage: 5
    },
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    loadingServiceRequestTypes: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    attachmentsObj: [{ id: 1, url: null }],
    isFileUploading: false,
    permissions: {}
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    },
    requestId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    // completionDate: function() {
    //   this.formattedCompletionDate = this.formatDate(this.completionDate);
    // }
  },
  async mounted() {},
  async created() {
    let self = this;
    self.loading = true;
    // await this.getRecipients();
    self.userInfo = await self.mgr.getUser(); //.then(userInfo => (self.userInfo = userInfo));
    permissionsHelper.getPermissions().then(self.getPermissions);
    // await self.getServiceRequestPriorities();
    await self.getComunityInfo();
    await self.getServiceRequestTypes();
    await self.getServiceRequest();

    self.loading = false;
  },
  methods: {
    printRequest() {
      window.print();
    },
    getAssignedTo(assignedTo) {
      return assignedTo?.fullName;
    },
    getCreatedBy(createdBy) {
      return createdBy?.fullName;
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        {
          title: "Service Requests",
          route: "/customerView/" + this.customerId + "/requests"
        },
        { title: "Update Service Request" }
      ]);
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        {
          title: "Service Requests",
          route: "/customerView/" + this.customerId + "/requests"
        },
        { title: "Update Service Request" }
      ];
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);

            this.ynTeamItems.push(this.communityInfo.accountManager);
            this.assignedTo = this.communityInfo.accountManager.id;

            this.createBreadcrumb();
            this.setInternalBreadcrumbs();

            this.newRequest.communityName = this.communityInfo.name;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    // async getServiceRequestPriorities() {
    //   this.loading = true;
    //   this.isLoading = true;
    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: API_SERVICE_REQUEST_PRIORITIES
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         this.priorityItems = response.data.$values;
    //       } else {
    //         this.$snackbar.showMessage({
    //           content: response.data || "Something went wrong!",
    //           color: "red"
    //         });
    //       }
    //       this.loading = false;
    //       this.isLoading = false;
    //     });
    // },
    async getServiceRequestTypes() {
      this.loading = true;
      this.isLoading = true;
      this.loadingServiceRequestTypes = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_SERVICE_REQUEST_TYPES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.requestTypeItems = response.data.$values;

            if (this.communityInfo.name.includes("Del Webb Grande Dunes")) {
              let allowedTypes = [
                "General Service",
                "Crew, storm or other damage",
                "Request For Proposal"
              ];
              let allowedTypeIds = [6, 11, 12];
              this.requestTypeItems = this.requestTypeItems.filter(
                el =>
                  allowedTypes.includes(el.name) ||
                  allowedTypeIds.includes(el.id)
              );
            } else if (
              this.communityInfo.name.includes("Del Webb at Lakewood Ranch") ||
              this.communityInfo.name.includes("Del Webb Lakewood Ranch Area")
            ) {
              let disallowedTypes = ["Wildlife Damage", "Mulch Request"];
              let disallowedTypeIds = [14, 15];
              this.requestTypeItems = this.requestTypeItems.filter(
                el =>
                  !disallowedTypes.includes(el.name) &&
                  !disallowedTypeIds.includes(el.id)
              );
            } else if (
              this.communityInfo.name.includes("Sun City Peachtree Homes")
            ) {
              let allowedTypes = [
                "General Service",
                "Irrigation",
                "Mulch Request",
                "Request for Proposal",
                "Plant Request",
                "Sod Request",
                "Crew, storm or other damage"
              ];
              let allowedTypeIds = [4, 6, 11, 12, 14, 16, 17];
              this.requestTypeItems = this.requestTypeItems.filter(
                el =>
                  allowedTypes.includes(el.name) ||
                  allowedTypeIds.includes(el.id)
              );
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          this.loading = false;
          this.isLoading = false;
          this.loadingServiceRequestTypes = false;
        });
    },
    async getServiceRequest() {
      this.loading = true;
      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_SERVICE_REQUESTS,
          id: this.requestId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.serviceRequest = response.data;

            self.completionDate = new Date(
              self.serviceRequest.targetCompletionDate
            )
              .toISOString()
              .substr(0, 10);

            self.requestType = self.serviceRequest.requestType.id;

            let comments = self.serviceRequest.comments.$values;
            if (comments.length > 0) {
              let lastComment = comments.sort((a, b) => {
                return new Date(b.created) - new Date(a.created);
              })[0];
              self.assignedTo = lastComment.assignedTo?.id;
              // self.priority = lastComment.priority?.id;

              if (lastComment) {
                self.serviceRequest.isNew = lastComment.isNew;
                self.serviceRequest.isOpen = lastComment.isOpen;
                self.serviceRequest.isInProcess = lastComment.isInProcess;
                self.serviceRequest.isCompleted = lastComment.isCompleted;

                self.completionDate = new Date(lastComment.targetCompletionDate)
                  .toISOString()
                  .substr(0, 10);

                self.requestType = lastComment.requestType.id;
              }

              self.serviceRequest.targetCompletionDate =
                lastComment.targetCompletionDate;
            } else {
              self.assignedTo = self.serviceRequest.assignedTo?.id;
              // self.priority = self.serviceRequest.priority?.id;
            }

            if (self.serviceRequest.isCompleted) {
              self.requestStatus = "complete";
            } else if (self.serviceRequest.isInProcess) {
              self.requestStatus = "inProcess";
            } else {
              self.requestStatus = "open";
            }
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          self.loading = false;
        });
    },
    saveServiceRequest() {
      // this.newRequest.title = this.serviceRequest.title;
      this.newRequest.communityId = this.serviceRequest.communityId;

      this.newRequest.parentId = this.serviceRequest.id;

      this.newRequest.isNew = this.serviceRequest.isNew;
      this.newRequest.isOpen =
        !this.serviceRequest.isNew && this.requestStatus === "open";
      this.newRequest.isInProcess =
        !this.serviceRequest.isNew && this.requestStatus === "inProcess";
      this.newRequest.isCompleted = this.requestStatus === "complete";
      this.newRequest.skipNotification =
        process.env.VUE_APP_SKIP_NOTIFICATIONS == "1";

      this.newRequest.isInternal = false;

      let requestTypeObj = this.requestTypeItems[0];
      if (
        this.requestTypeItems.filter(el => el.id === this.requestType).length >
        0
      )
        requestTypeObj = this.requestTypeItems.filter(
          el => el.id === this.requestType
        )[0];

      this.newRequest.requestType = {
        id: requestTypeObj.id,
        name: requestTypeObj.name
      };

      this.newRequest.title = this.newRequest.requestType.name;

      // let priorityObj = this.priorityItems[0];
      // if (this.priorityItems.filter(el => el.id === this.priority).length > 0)
      //   priorityObj = this.priorityItems.filter(
      //     el => el.id === this.priority
      //   )[0];

      // this.newRequest.priority = {
      //   id: priorityObj.id,
      //   name: priorityObj.name
      // };

      let assignedToObj = this.ynTeamItems[0];
      if (this.ynTeamItems.filter(el => el.id === this.assignedTo).length > 0)
        assignedToObj = this.ynTeamItems.filter(
          el => el.id === this.assignedTo
        )[0];

      this.newRequest.assignedTo = {
        id: assignedToObj.id
      };
      this.newRequest.targetCompletionDate = this.serviceRequest.targetCompletionDate;

      this.newRequest.attachments = this.attachmentsObj
        .filter(i => i.url && i.url.length > 0)
        .map(i => {
          return { id: i.fileId, fileUrl: i.url };
        });
      this.newRequest.attachments = this.newRequest.attachments.filter(
        (item, index) => this.newRequest.attachments.indexOf(item) === index
      ); //remove duplicates

      if (
        !this.newRequest.assignedTo ||
        // !this.newRequest.title ||
        !this.newRequest.description ||
        // !this.newRequest.priority ||
        !this.newRequest.requestType
      ) {
        this.snackbarContent =
          "Please, populate all required fields (*) on the form";
        this.snackbarColor = "red";
        this.$snackbar.showMessage({
          content: this.snackbarContent,
          color: this.snackbarColor
        });
        return;
      }

      this.serviceRequestProcessing = true;
      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_SERVICE_REQUESTS}`,
          data: this.newRequest
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent =
              "Service Request has been updated successfully";
            self.snackbarColor = "green";

            self.$router.push({
              name: "CustomerViewRequests",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
          self.serviceRequestProcessing = false;
        });

      // this.saveCustomer();
    },
    // async getRecipients() {
    //   // let comunityItems = dataModels.communityDetails;
    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: API_EMPLOYEES
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         this.ynTeamItems = response.data;
    //       } else {
    //         this.$snackbar.showMessage({
    //           content: response.data || "Something went wrong!",
    //           color: "red"
    //         });
    //       }
    //     });
    // },
    setStartingAfter() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
        this.endingBefore = "";
      }
    },
    setEndingBefore() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.endingBefore = this.userInvoices[0].id;
        this.startingAfter = "";
      }
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    },
    isNeighborhoodRequest() {
      return (
        this.userInfo.profile.aspireRole == "Tenant/Resident - Neighborhood"
      );
    },
    isResident() {
      return (
        this.userInfo.profile.aspireRole == "Tenant/Resident - Neighborhood" ||
        this.userInfo.profile.aspireRole == "Tenant/Resident"
      );
    }
  }
};
</script>

<style lang="scss">
@media print {
  #kt_aside,
  #kt_header,
  #kt_subheader,
  #syncscape_communityInfo,
  #updateRequestForm {
    display: none !important;
  }
}
</style>
